import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../features';
import { fetchUser, logout, User } from '../features/user/userSlice';
import { Navbar, NavDropdown } from 'react-bootstrap';
import { useLocation } from 'react-router';
import MobileMenu from './NavBar/MobileMenu';
import GeneralAlert from './ui/GeneralAlert';
import cx from 'classnames';
import { clearEvent } from '../features/events/eventSlice';

import './NavBar.scss';
import { BASE_FE_URL } from './Footer';

interface Props {
  signedIn: boolean;
  firstName: string | null;
  lastName: string | null;
  registration_completed: boolean;
  type: 'client' | 'doctor' | null;
  nextEventId: any;
  user: User;
  onLogout: () => void;
}

const NavBar: React.FC<Props> = ({
  signedIn,
  onLogout,
  firstName,
  lastName,
  registration_completed,
  type,
  nextEventId,
  user,
}) => {
  const { t } = useTranslation();
  const router = useHistory();
  const [expanded, setExpanded] = useState<boolean>(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (expanded) {
      setExpanded(false);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const mainMenu = [
    {
      title: t('navbar.home'),
      href: '/',
    },
    {
      title: t('navbar.business'),
      href: '/faq/business',
    },
    {
      title: t('navbar.about'),
      href: '/about',
    },
    {
      title: t('navbar.therapists'),
      href: '/therapists',
    },
    {
      title: t('navbar.blog'),
      href: '/blog',
    },
    {
      title: t('navbar.contact'),
      href: '/contact',
    },
    {
      title: t('navbar.faq'),
      href: '/faq',
    },
  ];

  const onUpcomingSessionClick = async () => {
    await dispatch(clearEvent());
    await dispatch(fetchUser());
  };

  const paidClientAuthenticatedMenu = [
    {
      title: t('navbar.upcomingConsultation'),
      href: '/client/session',
      onClick: onUpcomingSessionClick,
    },
    { title: t('common.consultationHistory'), href: '/client/sessions' },
    { title: t('navbar.settings'), href: '/client/settings' },
  ];

  const therapistAuthenticatedMenu = [
    {
      title: t('navbar.upcomingConsultation'),
      href: `/therapist/session/${nextEventId}`,
      hide: nextEventId === null,
    },
    { title: t('navbar.consultationCalendar'), href: '/therapist/calendar' },
    { title: t('common.sessions'), href: '/therapist/sessions' },
    { title: t('doc.paymentList'), href: '/therapist/payments' },
    { title: t('navbar.personalInfo'), href: '/therapist/personal-info' },
  ];

  const renderAuthenticatedDesktopMenu = () => {
    const primaryMenu = () => {
      let menu = null;

      if (type === 'client') {
        if (user.pricing === 'paid' && registration_completed) {
          menu = paidClientAuthenticatedMenu.map((menuItem, index) =>
            renderMenuItem(menuItem, index)
          );
        } else {
          return null;
        }
      } else {
        menu = therapistAuthenticatedMenu.map((menuItem, index) =>
          renderMenuItem(menuItem, index)
        );
      }

      return (
        <div>
          {menu}
          <NavDropdown.Divider />
        </div>
      );
    };

    const secondaryMenu = () => {
      return (
        <div className='d-block d-md-none'>
          {mainMenu.map((menuItem, index) => renderMenuItem(menuItem, index))}
          <NavDropdown.Divider />
        </div>
      );
    };

    const onLogoutClicked = () => {
      setExpanded(false);
      onLogout();
      router.push('/'); // Navigate to home page after logout
    };

    const renderFullName = () => {
      if (firstName === null) {
        return t('navbar.menu');
      }

      let fullName = firstName;

      if (lastName !== null) {
        fullName += ` ${lastName}`;
      }

      return fullName;
    };

    if (pathname === '/login') {
      return null;
    }

    return (
      <div className='text-right'>
        <div className='d-block d-md-none'>
          <div className='text-right text-lg-left'>
            {primaryMenu()}
            {secondaryMenu()}
            <button onClick={onLogoutClicked} className='dropdown-item text-flint'>
              {t('navbar.logout')}
            </button>
          </div>
        </div>
        <NavDropdown
          title={
            <span>
              <span className='mr-2 d-inline-block w-10p h-10p bg-primary rounded-circle' />
              <span className='text-flint'>{renderFullName()}</span>
            </span>
          }
          style={{ color: '#6d6966' }}
          id='basic-nav-dropdown'
          className='d-none d-md-block'
          renderMenuOnMount={true}
        >
          <div>
            {primaryMenu()}
            {secondaryMenu()}
            <button onClick={onLogoutClicked} className='dropdown-item text-flint bg-white'>
              {t('navbar.logout')}
            </button>
          </div>
        </NavDropdown>
      </div>
    );
  };

  const goToLogin = () => {
    router.push('/login'); // Navigate to local '/login' route
  };

  const renderUnauthenticatedDesktopMenu = () => {
    if (pathname === '/') {
      return (
        <button className='w-100p btn btn-outline-flint ml-2 rounded' onClick={goToLogin}>
          {t('navbar.logIn')}
        </button>
      );
    }

    return (
      <Link to='/registration/welcome'>
        <button className='w-100p btn btn-primary ml-2 rounded'>
          {t('landing.begin')}
        </button>
      </Link>
    );
  };

  const navigateToHref = (href) => {
    setExpanded(false);
    router.push(href);
  };

  const renderMenuItem = (menuItem, key) => {
    if (menuItem.hide) {
      return null;
    }

    if (menuItem.divider) {
      return <NavDropdown.Divider key={`divider-${key}`} />;
    }

    const onClick = () => {
      if (menuItem.onClick !== undefined) {
        menuItem.onClick();
      }

      navigateToHref(menuItem.href);
    };

    if (menuItem.href && menuItem.title) {
      return (
        <NavDropdown.Item
          key={menuItem.href}
          onClick={onClick}
          className={cx('dropdown-item text-flint bg-white', {
            'fw-700': pathname === menuItem.href,
          })}
        >
          {menuItem.title}
        </NavDropdown.Item>
      );
    }
  };

  const renderMobileAuthenticationButton = () => {
    if (signedIn) {
      return null;
    }

    if (pathname === '/') {
      return (
        <button
          className='auth-button btn btn-outline-flint ml-0 ml-sm-2 rounded d-md-none mr-2'
          onClick={goToLogin}
        >
          {t('navbar.logIn')}
        </button>
      );
    }

    return (
      <Link to='/registration/welcome' className='d-block d-md-none mr-2'>
        <button className='auth-button btn btn-primary ml-0 ml-sm-2 rounded'>
          {t('landing.begin')}
        </button>
      </Link>
    );
  };

  const renderMenu = (title, href) => {
    const fullHref = `${BASE_FE_URL}${href}`;
    return (
      <a
        key={href}
        href={fullHref}
        className='nav-link text-flint mr-2 py-0'
        onClick={() => setExpanded(false)}
      >
        {title}
      </a>
    );
  };

  const memoedDesktopMainMenu = useMemo(
    () => (
      <div className='d-flex align-items-center'>
        {mainMenu.map(({ title, href }) => renderMenu(title, href))}
      </div>
    ),
    [mainMenu]
  );

  const memoedDesktopMenu = useMemo(() => {
    return (
      <>
        {memoedDesktopMainMenu}
        {signedIn ? renderAuthenticatedDesktopMenu() : renderUnauthenticatedDesktopMenu()}
      </>
    );
    // eslint-disable-next-line
  }, [signedIn, pathname]);

  const openMobileMenu = () => {
    document.body.style.overflowY = 'hidden';
    document.getElementById('root').style.overflowY = 'hidden';
    setExpanded(true);
  };

  const closeMobileMenu = () => {
    document.body.style.overflowY = null;
    document.getElementById('root').style.overflowY = null;
    setExpanded(false);
  };

  if (pathname === '/login') {
    return null;
  }

  return (
    <>
      <Navbar expand='md' expanded={expanded} className='navBar bg-white py-3 sticky-top'>
        <a
          href='https://www.tokitus.com'
          className='navbar-brand py-0'
          onClick={() => setExpanded(false)}
        >
          <img
            srcSet='/images/tokitus-logo-v2-2x.png 2x'
            src='/images/tokitus-logo-v2.png'
            alt='Tokitus Logo'
            width='142'
            height='32'
          />
        </a>
        <div className='d-flex navbar-menu-wrap mobile-menu'>
          {renderMobileAuthenticationButton()}
          <Navbar.Toggle
            onClick={openMobileMenu}
            aria-controls='basic-navbar-nav'
            style={{ border: 0 }}
          >
            <div className='c-pointer w-30p'>
              <div className='bg-primary h-3p mb-1' />
              <div className='bg-primary h-3p mb-1' />
              <div className='bg-primary h-3p' />
            </div>
          </Navbar.Toggle>
        </div>

        <Navbar.Collapse id='basic-navbar-nav'>
          <div className='navbar-nav ml-auto d-none d-md-flex'>{memoedDesktopMenu}</div>
          <div className='navbar-nav ml-auto d-flex d-md-none'>
            <MobileMenu
              user={user}
              mainMenu={mainMenu}
              closeMenu={closeMobileMenu}
              paidClientAuthenticatedMenu={paidClientAuthenticatedMenu}
              therapistAuthenticatedMenu={therapistAuthenticatedMenu}
              onLogout={onLogout}
              currentPath={pathname}
              BASE_FE_URL={BASE_FE_URL} // Pass BASE_FE_URL to MobileMenu
            />
          </div>
        </Navbar.Collapse>
      </Navbar>
      {signedIn && (
        <GeneralAlert
          storageName='hideEmailAlert'
          display={user.email === null && user.registration_completed}
          dismissible
        >
          {t('navbar.emailNotifications')}{' '}
          <Link to='/client/settings' className='text-flint'>
            {t('navbar.inSettings')}
          </Link>
        </GeneralAlert>
      )}
    </>
  );
};

const mapStateToProps = ({ user }: RootState) => ({
  user,
  signedIn: Boolean(user.token),
  firstName: user.first_name || null,
  lastName: user.last_name || null,
  registration_completed: Boolean(user.registration_completed),
  type: user.type || null,
  nextEventId: user.next_event_id || null,
});

const mapDispatchToProps = {
  onLogout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
