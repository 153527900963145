import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { User } from '../../features/user/userSlice';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import './MobileMenu.scss';

interface Props {
  user: User;
  mainMenu: {
    title: string;
    href: string;
  }[];
  paidClientAuthenticatedMenu: {
    title: string;
    href: string;
  }[];
  therapistAuthenticatedMenu: {
    title: string;
    href: string;
  }[];
  closeMenu: () => void;
  onLogout: () => void;
  currentPath: string;
  BASE_FE_URL: string;
}

const MobileMenu = ({
  user,
  mainMenu,
  closeMenu,
  paidClientAuthenticatedMenu,
  therapistAuthenticatedMenu,
  onLogout,
  currentPath,
  BASE_FE_URL,
}: Props) => {
  const { t } = useTranslation();
  const signedIn = Boolean(user.token);

  const onLogoutClicked = useCallback(() => {
    closeMenu();
    onLogout();
  }, [closeMenu, onLogout]);    

  const renderFullName = useCallback(() => {
    const { first_name, last_name } = user;

    let fullName = first_name;

    if (last_name !== null) {
      fullName += ` ${last_name}`;
    }

    return fullName;
  }, [user]);

  const renderMenuHeader = useCallback(
    () => (
      <div className='menuHeader'>
        {signedIn && user.first_name ? (
          <div className='nameWrap'>
            <div className='dot' />
            <div className='fullName'>{renderFullName()}</div>
          </div>
        ) : (
          <a href='https://www.tokitus.com' className='mr-3' onClick={closeMenu}>
            <img
              srcSet='/images/tokitus-logo-v2-2x.png 2x'
              src='/images/tokitus-logo-v2.png'
              alt='Tokitus Logo'
              width='142'
              height='32'
            />
          </a>
        )}

        <div className='closeButton' onClick={closeMenu}>
          &times;
        </div>
      </div>
    ),
    [user.first_name, closeMenu, signedIn, renderFullName]
  );

  const renderMenuItem = useCallback(
    (menuItem) => {
      if (menuItem.hide) {
        return null;
      }

      const { href, title } = menuItem;

      return (
        <div key={href}>
          <Link
            to={href}
            onClick={closeMenu}
            className={cx('text-shark nav-link fs-20', {
              'fw-700 menu-item-underline': currentPath === href,
            })}
          >
            {title}
          </Link>
        </div>
      );
    },
    [closeMenu, currentPath]
  );

  const renderMenu = useCallback(
    (title, href) => {
      const fullHref = `${BASE_FE_URL}${href}`;
      return (
        <a
          key={href}
          href={fullHref}
          className='nav-link text-shark fs-20'
          onClick={closeMenu}
        >
          {title}
        </a>
      );
    },
    [closeMenu, BASE_FE_URL]
  );

  const renderMainMenu = useCallback(
    () => (
      <div className='mainMenu'>
        {mainMenu.map(({ title, href }) => renderMenu(title, href))}
      </div>
    ),
    [renderMenu, mainMenu]
  );

  const renderClientAuthenticatedMenu = useCallback(() => {
    if (user.pricing === 'paid' && user.registration_completed) {
      return paidClientAuthenticatedMenu.map((menuItem) => renderMenuItem(menuItem));
    }
  }, [
    user.registration_completed,
    user.pricing,
    paidClientAuthenticatedMenu,
    renderMenuItem,
  ]);

  const renderTherapistAuthenticatedMenu = useCallback(
    () => therapistAuthenticatedMenu.map((menuItem) => renderMenuItem(menuItem)),
    [therapistAuthenticatedMenu, renderMenuItem]
  );

  const goToLogin = () => {
    closeMenu();
    // Assuming you have a login route in your app
    window.location.href = '/login';
  };

  const renderAuthenticationButtons = () => {
    return (
      <div className='authenticationButtons'>
        <Link to='/registration/welcome' onClick={closeMenu}>
          <button className='btn btn-lg btn-primary signUpButton w-100 rounded mb-3'>
            {t('landing.begin')}
          </button>
        </Link>
        <button
          className='btn btn-lg btn-outline-mako logInButton w-100 rounded'
          onClick={goToLogin}
        >
          {t('navbar.logIn')}
        </button>
      </div>
    );
  };

  const renderLogout = useCallback(
    () => (
      <button onClick={onLogoutClicked} className='text-shark nav-link'>
        {t('navbar.logout')}
      </button>
    ),
    [onLogoutClicked, t]
  );

  const renderAuthenticatedMenu = useCallback(() => {
    let authenticatedMenu = null;

    if (user.type === 'client') {
      authenticatedMenu = renderClientAuthenticatedMenu();
    } else {
      authenticatedMenu = renderTherapistAuthenticatedMenu();
    }

    return (
      <>
        <div className='authenticatedMenu'>
          {authenticatedMenu}
          {renderLogout()}
        </div>
        <div className='menuBorder' />
      </>
    );
  }, [
    user.type,
    renderClientAuthenticatedMenu,
    renderTherapistAuthenticatedMenu,
    renderLogout,
  ]);

  return (
    <div className='mobileMenu'>
      {renderMenuHeader()}
      <div className={`menuBody ${signedIn ? '' : 'unauthenticated'}`}>
        {signedIn && renderAuthenticatedMenu()}
        {renderMainMenu()}
      </div>
      <div className='menuFooter'>
        {!signedIn && renderAuthenticationButtons()}
        <div className='rightsText'>© 2020-2021 {t('footer.allRightsReserved')}.</div>
      </div>
    </div>
  );
};

export default MobileMenu;
